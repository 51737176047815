import styled from "styled-components";

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto;
    width: 90%;
    max-width: 1000px;
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 48px;
`;

export const StyledTableCard = styled.div`
    margin-top: 8px;
    padding: 12px 0;
    width: 100%;
    border-radius: 20px;
    border: 2px solid #e8e8e8;
`

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 4px;
    border-radius: 20px;

    thead {
        font-weight: 500;
        font-size: 17px;
    }
`;

export const StyledTableRow = styled.tr`
    &:nth-child(even) {
        background-color: #f5f7fa;
    }

    transition: background-color 200ms ease;

    &:hover {
        background-color: rgba(225, 235, 245, 0.7);
    }
`;

export const StyledTableCell = styled.td`
    padding: 16px;
    border-bottom: 1px solid #e5edf3;
    text-align: left;

    &:last-child {
        text-align: center;
    }
`;

export const StyledIconButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 8px;

    img {
        width: 24px;
        height: 24px;
        opacity: 0.8;
    }

    &:hover {
        opacity: 1;
    }
`;

export const AddUserWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

    img {
        width: 32px;
        height: 32px;
    }
`;
export const Header = styled.div`
    width: 100%;
    margin-bottom: 8px;
`;
