import React, {useEffect, useRef} from 'react';
import {
    Dot,
    ErrorMessageSystemText,
    LoadingDots,
    MessageRate,
    MessagesContainer,
    MessageSystem,
    MessageSystemAuthor,
    MessageSystemHeader,
    MessageSystemHeaderIcons,
    MessageSystemText,
    MessageYou,
    MessageYouAuthor,
    MessageYouText,
    StyledButton,
} from './ChatMessages.styled';
import PlusIcon from 'assets/icons/plus.svg';
import MinusIcon from 'assets/icons/minus.svg';
import AsystentAvatar from 'assets/images/asystent_it_avatar.png';
import YouAvatar from 'assets/images/avatar.png';
import CopySvg from "assets/icons/copy.svg";
import MoreSvg from "assets/icons/more.svg";
import {Message} from "../pages/Chat";

const ChatMessages = ({messages}: { messages?: Message[] }) => {

    const containerRef = useRef<HTMLDivElement>(null);
    const copyToClipboard = (text: string) => {
        void navigator.clipboard.writeText(text)
    };
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [messages]);
    if (messages?.length === 0) {
        return null;
    }

    return (
        <MessagesContainer ref={containerRef}>
            {messages?.map((message, index) =>
                message.isSystem ? (
                    <MessageSystem key={index}>
                        <MessageSystemHeader>
                            <MessageSystemAuthor>
                                <img src={AsystentAvatar} alt="Asystent IT"/>
                                {message.author}
                            </MessageSystemAuthor>
                            {!message.isLoading && !message.isError && (
                                <MessageSystemHeaderIcons>
                                    <StyledButton onClick={() => copyToClipboard(message.text)}>
                                        <img src={CopySvg} alt="Copy"/>
                                    </StyledButton>
                                    <StyledButton>
                                        <img src={MoreSvg} alt="More"/>
                                    </StyledButton>
                                </MessageSystemHeaderIcons>
                            )}
                        </MessageSystemHeader>
                        {message.isLoading ? (
                            <LoadingDots>
                                <Dot/>
                                <Dot/>
                                <Dot/>
                            </LoadingDots>
                        ) : message.isError ? (
                            <ErrorMessageSystemText>{message.text}</ErrorMessageSystemText>
                        ) : (
                            <MessageSystemText>{message.text}</MessageSystemText>
                        )}
                        {!message.isLoading && !message.isError && (
                            <MessageRate>
                                <span>Oceń odpowiedź</span>
                                <img src={PlusIcon} alt="Rate up"/>
                                <img src={MinusIcon} alt="Rate down"/>
                            </MessageRate>
                        )}
                    </MessageSystem>
                ) : (
                    <MessageYou key={index}>
                        <MessageYouAuthor>
                            <img src={YouAvatar} alt="User Avatar"/>
                            {message.author && <span>{message.author}</span>}
                        </MessageYouAuthor>
                        <MessageYouText>{message.text}</MessageYouText>
                    </MessageYou>
                )
            )}
        </MessagesContainer>
    );
};

export default ChatMessages;
