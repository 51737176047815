import React, {useState} from 'react';
import {IconButton,} from '@mui/material';
import {
    ChatCard,
    ChatContainer,
    ChatHeader,
    ChatWrapper,
    HeaderActions,
    LogoWrapper,
    PdfWrapper,
    StyledSwitch,
    UseDocumentsWrapper,
} from './Chat.styled';
import AsystentLogo from 'assets/images/asystent_it_logo.png';
import ChatMessages from "../components/ChatMessages";
import ChatInput from "../components/ChatInput";
import SettingsSvg from "assets/icons/settings.svg";
import {useNavigate} from "react-router-dom";
import {useApi} from "../hooks/UseApi";
import {useUser} from "../core/UserContext";
import ImagePreview from "../components/ImagePreview";
import DownloadButton from "../components/DownloadButton";

export interface Message {
    author: string,
    text: any,
    isSystem: boolean,
    isLoading?: boolean,
    isError?: boolean
}


const Chat = () => {
    const [messages, setMessages] = useState<Message[]>([]);
    const navigate = useNavigate();
    const {loading, error, sendMessage, downloadDocumentForId} = useApi();
    const [attachedFile, setAttachedFile] = useState<File | null>(null);
    const [checked, setChecked] = useState(false);
    const {user} = useUser();

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleNavigation = () => {
        navigate('/manage-documents');
    };

    const handleSendMessage = async (text: string) => {
        const isUserName = user?.name || user?.surname;
        const author = isUserName ? `${user?.name} ${user?.surname}` : '';
        const userMessage: Message = {author, text, isSystem: false};
        setMessages((prev) => [...prev, userMessage]);

        const loadingMessage: Message = {author: 'Asystent IT', text: '', isSystem: true, isLoading: true};
        setMessages((prev) => [...prev, loadingMessage]);

        const responseHandler =  (text: string) => {
            setMessages((prev) => {
                const updatedMessages = [...prev];
                const processedText = text.replace(/<\/img>/g, '').split(/(<br>|<img src='[^"]+'>|<a href='[^"]+'>.+?<\/a>)/g)
                const formattedText = processedText.map((chunk, index) => {
                    if (chunk === '<br>') {
                        return <br key={index}/>;
                    } else if (chunk.startsWith(`<img src='`)) {
                        const src = chunk.match(/<img src='([^"]+)'>/)?.[1];
                        if (src) {
                            return <ImagePreview src={src} key={index}/>;
                        }
                    } else if (chunk.startsWith('<a href=')) {
                        const hrefMatch = chunk.match(/<a href='([^']+)'>([^<]+)<\/a>/);
                        if (hrefMatch) {
                            const url = hrefMatch[1];
                            const fileName = hrefMatch[2];

                            const documentId = url.split('/').slice(-2, -1)[0];
                            return <DownloadButton key={index} documentId={documentId} fileName={fileName}
                                                   onDownload={downloadDocumentForId}/>;
                        }
                    }
                    return chunk;
                });

                updatedMessages[updatedMessages.length - 1] = {
                    author: 'Asystent IT',
                    text: formattedText.filter(chunk => chunk !== '</img>'),
                    isSystem: true,
                    isLoading: false,
                };

                return updatedMessages;
            });
        }

        await sendMessage(text, checked, responseHandler, attachedFile);
    };

    const handleFileChange = (file: File | null) => {
        setAttachedFile(file)
        if(file) {
            setChecked(true)
        }
    }

    return (
        <ChatContainer>
            <PdfWrapper>
                <div>
                    <canvas id="pdf-canvas"></canvas>
                    <div>
                        <button>Poprzednia</button>
                        <span>
              Strona: <span id="page-num"></span> / <span id="page-count"></span>
            </span>
                        <button>Następna</button>
                    </div>
                </div>
            </PdfWrapper>
            <ChatWrapper>
                <ChatCard>
                    <HeaderActions>
                        <IconButton color="primary" onClick={handleNavigation}>
                            <img src={SettingsSvg} alt="Manage Documents"/>
                        </IconButton>
                    </HeaderActions>
                    <LogoWrapper>
                        <img src={AsystentLogo} alt="Asystent IT Logo"/>
                    </LogoWrapper>
                    <UseDocumentsWrapper>
                        <StyledSwitch checked={checked}
                                      onChange={handleSwitchChange}/>
                        <span>{checked ? 'Wykorzystaj dokumenty' : 'Nie używaj dokumentów'}</span>
                    </UseDocumentsWrapper>
                    <ChatHeader $isBigSpace={messages.length === 0}>Jak mogę Ci dzisiaj pomóc?</ChatHeader>
                    <ChatMessages messages={messages}/>
                    <ChatInput onSendMessage={handleSendMessage} onFileChange={handleFileChange}/>
                </ChatCard>
            </ChatWrapper>
        </ChatContainer>
    );
};

export default Chat;
