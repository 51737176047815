import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {Header, StyledButton, StyledContainer, StyledForm, StyledTextField,} from "./Register.styled";

async function createUser(name: string, surname: string, email: string, password: string) {
    try {
        await axios.post(`/users`, {
            name,
            surname,
            email,
            password,
            role: 'USER'
        });
    } catch (error) {
        alert("Rejestracja nie powiodła się. Spróbuj ponownie.");
        console.error("Błąd rejestracji:", error);
    }
}

const CreateUser: React.FC = () => {
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleRegister = async () => {
        await createUser(name, surname, email, password);
        navigate("/login");
    };

    return (
        <StyledContainer>
            <Header>Utwórz Użytkownika</Header>
            <StyledForm autoComplete="off">
                <StyledTextField
                    label="Imię"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <StyledTextField
                    label="Nazwisko"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                />
                <StyledTextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <StyledTextField
                    label="Hasło"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <StyledButton onClick={handleRegister}>Utwórz</StyledButton>
            </StyledForm>
        </StyledContainer>
    );
};

export default CreateUser;
